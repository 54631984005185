<template>
  <v-container>
    <v-row class="justify-center align-self-start">
      <v-col class="col-md-8 col-sm-10">
        <v-card class="py-9 px-12">
          <v-card-title
            class="primary--text headline"
          >
            Whitelisting
          </v-card-title>

          <v-card-text>
            <h3
              id="iadops-v1"
              class="mb-3 subtitle-1 primary--text font-weight-bold"
            >
              Users
            </h3>

            <div>
              <h4>Admins</h4>

              <p>
                On or after September 21, administrators may navigate to the
                <router-link to="/internal/person">
                  Person page
                </router-link>
                to begin whitelisting users.
              </p>

              <p>
                Confirm the record does not already exist. If it does not,
                click the + icon in the top right icon menu to
                create a user. Add the user's email, assign the user
                the role of "external", and set the user's active field
                to TRUE.
              </p>
            </div>

            <div>
              <h4>Readers</h4>

              <p>
                Internal users with read access can view users by visiting the
                <router-link to="/internal/person">
                  Person page
                </router-link>. To have an external user added, contact the admin assocated with your team.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DeprecationNotes',
}
</script>
